import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import NavButtons from 'components/Advanced_User/INSTAR_MQTT_Broker/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Adding a Theme to Home Assistant",
  "path": "/Advanced_User/INSTAR_MQTT_Broker/Home_Assistant/Theming/",
  "dateChanged": "2019-03-26",
  "author": "Mike Polinowski",
  "excerpt": "Home Assistant is a open source home automation that puts local control and privacy first. Powered by a worldwide community of tinkerers and DIY enthusiasts. Perfect to run on a Raspberry Pi or a local server. Compatible with the camera models IN-6001 HD, IN-6012 HD, IN-6014 HD, IN-5905 HD, IN-5907 HD, IN-7011 HD, IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9020 Full HD",
  "image": "../../AU_SearchThumb_MQTT.png",
  "social": "/images/Search/AU_SearchThumb_HomeAssistant.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_MQTT_white.webp",
  "chapter": "Advanced User",
  "category": "smarthome",
  "type": "Home Assistant"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEOHelmet title='Adding a Theme to Home Assistant' dateChanged='2019-03-26' author='Mike Polinowski' tag='INSTAR IP Camera' description='Home Assistant is a open source home automation that puts local control and privacy first. Powered by a worldwide community of tinkerers and DIY enthusiasts. Perfect to run on a Raspberry Pi or a local server. Compatible with the camera models IN-6001 HD, IN-6012 HD, IN-6014 HD, IN-5905 HD, IN-5907 HD, IN-7011 HD, IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9020 Full HD' image='/images/Search/AU_SearchThumb_HomeAssistant.png' twitter='/images/Search/AU_SearchThumb_HomeAssistant.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Erweitert/INSTAR_MQTT_Broker/Home_Assistant/Theming/' locationFR='/fr/Advanced_User/INSTAR_MQTT_Broker/Home_Assistant/Theming/' crumbLabel="MQTT" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "adding-a-theme-to-home-assistant",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#adding-a-theme-to-home-assistant",
        "aria-label": "adding a theme to home assistant permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Adding a Theme to Home Assistant`}</h1>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#adding-your-theme"
        }}>{`Adding your Theme`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#automatically-switch-day--night-theme"
        }}>{`Automatically switch Day / Night Theme`}</a></li>
    </ul>
    {/* /TOC */}
    <p>{`Start by creating a themes directory in the Home Assistant configuration folder inside your home directory:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`cd`}</span>{` ~/.homeassistant
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`mkdir`}</span>{` themes`}</code></pre></div>
    <p>{`Now open the Home Assistant configuration file `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`configuration.yaml`}</code>{` inside the same directory and add the themes directory under `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`frontend:`}</code></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`frontend:
  themes: `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`!`}</span>{`include_dir_merge_named themes/`}</code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "884px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0a0926ba06c9a73d746c4ace361d6fbb/136a2/Home_Assistant_Themes_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "33.47826086956522%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAA+klEQVQY043My0rDQACF4XkNo02ayVwymWmS3qyoKxdBS7ELiwS0fYamiZkxofRqXaqV2NcVKYJIF4GPs/sP+Li/3obdYtT/Gt3uFcOf/Qy773fBdhAU4c1u2Ns9/vHQ2w6Ct/4VkC2RenZE4YSaEYNjhiIKx8yaMCu24RPSZVWTxtE/qqopUwNxx5cuTXyWeCyu88RjkuNUUCmwFDitEeVYGTEOAnGDJw2eulQJIjlStpmjSoYq+a8M6Rk+DCzbfHEq1m2+ajlTB+b7V6yXATbn7stZ7fXS31x4MxfnxFDwOCPl4nVHzFwy9+mibs89smw6q6Z4tk7KxN8TJXQFoFdiXwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0a0926ba06c9a73d746c4ace361d6fbb/e4706/Home_Assistant_Themes_01.avif 230w", "/en/static/0a0926ba06c9a73d746c4ace361d6fbb/d1af7/Home_Assistant_Themes_01.avif 460w", "/en/static/0a0926ba06c9a73d746c4ace361d6fbb/93034/Home_Assistant_Themes_01.avif 884w"],
              "sizes": "(max-width: 884px) 100vw, 884px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0a0926ba06c9a73d746c4ace361d6fbb/a0b58/Home_Assistant_Themes_01.webp 230w", "/en/static/0a0926ba06c9a73d746c4ace361d6fbb/bc10c/Home_Assistant_Themes_01.webp 460w", "/en/static/0a0926ba06c9a73d746c4ace361d6fbb/8af20/Home_Assistant_Themes_01.webp 884w"],
              "sizes": "(max-width: 884px) 100vw, 884px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0a0926ba06c9a73d746c4ace361d6fbb/81c8e/Home_Assistant_Themes_01.png 230w", "/en/static/0a0926ba06c9a73d746c4ace361d6fbb/08a84/Home_Assistant_Themes_01.png 460w", "/en/static/0a0926ba06c9a73d746c4ace361d6fbb/136a2/Home_Assistant_Themes_01.png 884w"],
              "sizes": "(max-width: 884px) 100vw, 884px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0a0926ba06c9a73d746c4ace361d6fbb/136a2/Home_Assistant_Themes_01.png",
              "alt": "Home Assistant Themes",
              "title": "Home Assistant Themes",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "adding-your-theme",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#adding-your-theme",
        "aria-label": "adding your theme permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Adding your Theme`}</h2>
    <p>{`There are a number of Home Assistant `}<a parentName="p" {...{
        "href": "https://www.awesome-ha.com/#themes"
      }}>{`themes available for download`}</a>{` on Home Assistant homepage. Download the code of themes that you like and save them into a `}<em parentName="p">{`.yaml`}</em>{` file inside your themes folder.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "882px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c69cf652dc51d2b3fd41e530c3df8b0a/90712/Home_Assistant_Themes_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50.86956521739131%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABMUlEQVQoz4XQ3U6DMAAF4D2HZjM4fsYYtLTjf4VilkUvfA41XpllOgrKylYYm+6ZjT4AJOf2S845gyzVPlJli5Q1gBuMPpFRmuPs9jobDzN5xJSuDNoFEo7ZhGCP1FOKjgTsQ8jgjEOt0CUmD7vwwZvtbFWEoARqQ1AdWvvE5bF/8IxCl7JuXOHpzlJEaHE0qSPYENim+LLyThTvLLWntggsjvQ6AhWatAk+EniI5ywKKqwX2k0ProlduVMRgcqdnVdBG8OSeGuMOVSLicQ6Pxu8PoyeHq/eQu0Z0xefvvueoA6fT7mt/ePOzeXSzKn26epF4BYeZo7NDCVXR9l42C3/cINNAY1zgn9S+7LE39RuE3y6c0pTznrxlsibWPoiZr6IchJsI/94T9sElZbSi38B+9WiT+T94VsAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c69cf652dc51d2b3fd41e530c3df8b0a/e4706/Home_Assistant_Themes_02.avif 230w", "/en/static/c69cf652dc51d2b3fd41e530c3df8b0a/d1af7/Home_Assistant_Themes_02.avif 460w", "/en/static/c69cf652dc51d2b3fd41e530c3df8b0a/670b7/Home_Assistant_Themes_02.avif 882w"],
              "sizes": "(max-width: 882px) 100vw, 882px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c69cf652dc51d2b3fd41e530c3df8b0a/a0b58/Home_Assistant_Themes_02.webp 230w", "/en/static/c69cf652dc51d2b3fd41e530c3df8b0a/bc10c/Home_Assistant_Themes_02.webp 460w", "/en/static/c69cf652dc51d2b3fd41e530c3df8b0a/9d646/Home_Assistant_Themes_02.webp 882w"],
              "sizes": "(max-width: 882px) 100vw, 882px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c69cf652dc51d2b3fd41e530c3df8b0a/81c8e/Home_Assistant_Themes_02.png 230w", "/en/static/c69cf652dc51d2b3fd41e530c3df8b0a/08a84/Home_Assistant_Themes_02.png 460w", "/en/static/c69cf652dc51d2b3fd41e530c3df8b0a/90712/Home_Assistant_Themes_02.png 882w"],
              "sizes": "(max-width: 882px) 100vw, 882px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c69cf652dc51d2b3fd41e530c3df8b0a/90712/Home_Assistant_Themes_02.png",
              "alt": "Home Assistant Themes",
              "title": "Home Assistant Themes",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    {/* ## Adding a Switch for your Themes
     Now add a configuration file `input-select.yaml` next to the main config file:
     
     ```yaml
     hass_theme:
      name: Theme Switcher
      options: 
       - 'default'
       - 'dark-blue'
       - 'dark-grey'
       - 'dark-red'
       - 'midnight'
       - 'transparent'
      icon: 'mdi:theme-light-dark'
     ``` */}
    {/* ## Adding a Theme Automation Component
     The next thing that you need to do is create an automation that would set up a specific theme every time you open or sign in to Home Assistant from any device. To do that, open the `automations.yaml` file and enter the following:
     
     ```yaml
     - id: hass_theme
        alias: hass_theme
        initial_state: 'on'
        trigger:
          - platform: state
            entity_id: input_select.hass_theme
        action:
          - service: frontend.set_theme
            data_template:
              name: "{{ states.input_select.hass_theme.state }}"
     ```
     
     Under `alias`, we’re setting up the name for the automation, under `trigger`, we’re using the state of the input selector - that means whatever we choose as active theme will be set as the state of our automation. Under `action`, we’re using the service, `frontend.set_theme`. This is the command that is going to set up the specific theme. And last, under data, we’re entering the name for the theme that we want to be enabled and that will be set by our selection from the list of themes.
     Save the file and then restart Home Assistant to apply the changes. Now, every time you restart or open Home Assistant from any device, you would get the same theme that you selected earlier. */}
    <h2 {...{
      "id": "automatically-switch-day--night-theme",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#automatically-switch-day--night-theme",
        "aria-label": "automatically switch day  night theme permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Automatically switch Day / Night Theme`}</h2>
    <p>{`What if you want a dark theme to be enabled at night time and then another theme during the daytime? Just open `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`automations.yaml`}</code>{` file and replace the previous automation with the following:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yaml"
    }}><pre parentName="div" {...{
        "className": "language-yaml"
      }}><code parentName="pre" {...{
          "className": "language-yaml"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`alias`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Set Day/Night Theme'`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`trigger`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`platform`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` homeassistant
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`event`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` start
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`platform`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` state
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`entity_id`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` sun.sun
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`to`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` above_horizon
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`platform`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` state
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`entity_id`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` sun.sun
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`to`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` below_horizon
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`action`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`service_template`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` frontend.set_theme
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`data_template`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token scalar string"
          }}>{`
          {% if states.sun.sun.state == "above_horizon" %}
            clear-day
          {% else %}
            clear
          {% endif %}`}</span></code></pre></div>
    <p>{`This automation switches between the two themes `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`clear-day`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`clear`}</code>{`. This is the name you choose at the top of the theme `}<em parentName="p">{`.yaml`}</em>{` file and will be different depending on the themes you downloaded.`}</p>
    <p>{`Make sure that the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`automations.yaml`}</code>{` file is included inside the main `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`configuration.yaml`}</code>{` - which should be the case by default:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yaml"
    }}><pre parentName="div" {...{
        "className": "language-yaml"
      }}><code parentName="pre" {...{
          "className": "language-yaml"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`# Configure a default setup of Home Assistant (frontend, api, etc)`}</span>{`
`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`default_config`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`

`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`group`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token tag"
          }}>{`!include`}</span>{` groups.yaml
`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`script`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token tag"
          }}>{`!include`}</span>{` scripts.yaml
`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`automation`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token tag"
          }}>{`!include`}</span>{` automations.yaml

`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`frontend`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`themes`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token tag"
          }}>{`!include_dir_merge_named`}</span>{` themes/`}</code></pre></div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      